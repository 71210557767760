import React from "react";
import "./Loader.scss";
import logo from "../../assets/egg.png";

function Loader({ msg }) {
  return (
    <div className="loader-holder">
      <img src={logo} />
      <p>{msg}</p>
    </div>
  );
}

export default Loader;
