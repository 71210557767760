const emailValidation = (str) => {
  const email_regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email_regx.test(str);
};

const usernameValidation = (str) => {
  const username_regx = /^[a-zA-Z0-9_.]{5,18}$/;
  return username_regx.test(str);
};

const passwordValidation = (str) => {
  const password_regx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
  return password_regx.test(str);
};

module.exports = { emailValidation, usernameValidation, passwordValidation };
