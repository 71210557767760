import React from "react";
import { useNavigate } from "react-router-dom";

function Main() {
  const navigate = useNavigate();
  return (
    <div>
      <button
        onClick={() => {
          navigate("/signup");
        }}
      >
        {" "}
        signup{" "}
      </button>
    </div>
  );
}

export default Main;
