import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Signup.scss";
import logo from "../../assets/egg.png";
import Loader from "../../utils/Loader/Loader";
import Toast from "../../utils/Toast/Toast";
import {
  emailValidation,
  usernameValidation,
  passwordValidation,
} from "../../utils/Validations/Validations";

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailCon, setEmailCon] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();

  // toast useEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setToast(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [toast]);

  // handle Signup button click
  const handleClick = () => {
    if (
      username == "" ||
      password == "" ||
      email == "" ||
      confirmPassword == ""
    ) {
      setToastMsg("Please fill in all fields.");
      setToast(true);
    } else if (!usernameValidation(username)) {
      setToastMsg(
        "Username must be 5 to 18 characters long and can only contain letters, numbers,\n underscores (_), and periods (.)"
      );
      setToast(true);
    } else if (!emailValidation(email)) {
      setToastMsg("Enter a valid email");
      setToast(true);
    } else if (!passwordValidation(password)) {
      setToastMsg(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*())."
      );
      setToast(true);
    } else if (password !== confirmPassword) {
      setToastMsg("Password and Confirm Password fields do not match.");
      setToast(true);
    } else {
      console.log(
        "username: " +
          username +
          "\npassword: " +
          password +
          "\nemail: " +
          email +
          "\nconfirm password: " +
          confirmPassword
      );
      setEmailCon(true);
    }
  };

  // handle otp button click
  const handleOtpClick = () => {
    if (otp == "") {
      setToastMsg("Enter OTP");
      setToast(true);
    } else {
      setLoader(true);
    }
  };

  return (
    <div className="main">
      <div className="signup-form">
        <div className="logo-holder">
          <img src={logo} />
          <h2>Omegglet</h2>
          <p>Meet Strangers</p>
        </div>
        <div className="info-holder">
          <div className="info-con">
            <h1>Sign Up</h1>
            <p>
              {emailCon
                ? "Enter the 4-digit code sent on your email"
                : "Please fill the details to sign up."}
            </p>
          </div>
          <div className="detials-con">
            {emailCon ? (
              <>
                <input
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  type="number"
                  placeholder="Otp"
                />
                <button onClick={handleOtpClick}>Verify OTP</button>
                <p className="wrong-email" onClick={() => setEmailCon(false)}>
                  Entered a wrong email?
                </p>
              </>
            ) : (
              <>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="Username"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                />
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Password"
                />
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Confirm Password"
                />
                <button onClick={handleClick}>Sign Up</button>
                <p>
                  Already have an account?{" "}
                  <span onClick={() => navigate("/login")}>Login now</span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {loader ? <Loader msg="Please wait Signing in..." /> : null}
      {toast ? <Toast msg={toastMsg} /> : null}
    </div>
  );
}

export default Signup;
