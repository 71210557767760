import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import logo from "../../assets/egg.png";
import Loader from "../../utils/Loader/Loader";
import Toast from "../../utils/Toast/Toast";
import {
  usernameValidation,
  passwordValidation,
} from "../../utils/Validations/Validations";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();

  // toast useEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setToast(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [toast]);

  // handle Signup button click
  const handleClick = () => {
    if (username == "" || password == "") {
      setToastMsg("Please fill in all fields.");
      setToast(true);
    } else if (!usernameValidation(username)) {
      setToastMsg(
        "Username must be 5 to 18 characters long and can only contain letters, numbers,\n underscores (_), and periods (.)"
      );
      setToast(true);
    } else if (!passwordValidation(password)) {
      setToastMsg(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*())."
      );
      setToast(true);
    } else {
      console.log("username: " + username + "\npassword: " + password);
      setLoader(true);
    }
  };

  return (
    <div className="main">
      <div className="login-form">
        <div className="logo-holder">
          <img src={logo} />
          <h2>Omegglet</h2>
          <p>Meet Strangers</p>
        </div>
        <div className="info-holder">
          <div className="info-con">
            <h1>Login</h1>
            <p>Please login to your account.</p>
          </div>
          <div className="detials-con">
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="Username"
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
            <p className="forgot-pass">Forgot Password?</p>
            <button onClick={handleClick}>Login</button>
            <p>
              Don't have an account?{" "}
              <span onClick={() => navigate("/signup")}>Sign Up</span>
            </p>
          </div>
        </div>
      </div>
      {loader ? <Loader msg="Please wait Logging in..." /> : null}
      {toast ? <Toast msg={toastMsg} /> : null}
    </div>
  );
}

export default Login;
